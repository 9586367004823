import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';  // Solo esta importación es necesaria
import { Helmet } from 'react-helmet';

const Home = () => {
  return (
    <div>
      <Helmet>
        <meta property="og:title" content="Página de Inicio" />
        <meta property="og:description" content="Bienvenido a la página de inicio de mi sitio." />
        <meta property="og:image" content="https://mi-sitio.com/home.jpg" />
        <meta property="og:url" content="https://mi-sitio.com/" />
        <title>Página de Inicio</title>
      </Helmet>
      <h1>Bienvenido a la Página de Inicio</h1>
    </div>
  );
};

const About = () => {
  return (
    <div>
      <Helmet>
        <meta property="og:title" content="Acerca de Nosotros" />
        <meta property="og:description" content="Descubre más sobre nuestro equipo y misión." />
        <meta property="og:image" content="https://puranumismatica.celajescr.com/86760aa0-bdf7-4fa5-8821-ae2d9af68ed8.webp" />
        <meta property="og:url" content="https://mi-sitio.com/about" />
        <title>Acerca de Nosotros</title>
      </Helmet>
      <h1>Acerca de Nosotros</h1>
    </div>
  );
};

const App = () => {
  return (
    <Router>
      <Routes>  {/* Usar Routes en lugar de Switch en la versión 6 de react-router-dom */}
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
      </Routes>
    </Router>
  );
};

export default App;
